import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"

const About = () => {
  const stack = [
    { id: 1, title: "MongoDB" },
    { id: 2, title: "MySQL" },
    { id: 3, title: "PostgreSQL" },
    { id: 4, title: "Redux" },
    { id: 5, title: "Redux Toolkit" },
    { id: 6, title: "Axios" },
    { id: 7, title: "ReactJS" },
    { id: 8, title: "NextJS" },
    { id: 9, title: "GatsbyJS" },
    { id: 10, title: "ExpressJS" },
    { id: 11, title: "Django" },
    { id: 12, title: "JavaScript" },
    { id: 13, title: "TypeScript" },
    { id: 14, title: "HTML" },
    { id: 15, title: "CSS" },
    { id: 16, title: "Jenkins" },
    { id: 17, title: "NPM" },
    { id: 18, title: "MUI" },
];
  const info = `
  Welcome to my little space on the web , I'm Abhishek Shukla , A Software Engineer with strong expertise in Frontend domain and an upcoming Masters in Engineering student at Concordia University in Montreal.

  I'm proficient in the JS ecosystem and technologies sorrounding it. I have made significiant impact in every organization I have worked with.
  
  Apart from being a proficient developer , I have won 2 hackathons, all time best of being in top 5% on Codewars Leaderboard and have published 2 NPM packages and have also published a technical paper. 
  
  Apart from coding , I love exploring what the next-gen technolgies are and how they will impact the industry and also I'm very passionate about making melodies on FL studio :)
  `

  // const paragraphs = [
  //   {
  //     info: "Welcome to my digital oasis! I'm Abhishek Shukla, a dynamic Software Engineer with an insatiable curiosity for all things Frontend. I'm an upcoming Master in Engineering student at the prestigious Concordia University in Montreal, I bring a powerful blend of academic excellence and hands-on expertise to the table."
  //   },
  //   {
  //     info: "With a deep-rooted love for the JavaScript ecosystem and its ever-evolving technologies, I thrive on pushing boundaries and creating innovative solutions. My knack for leaving an indelible mark on organizations I've been a part of is no secret, as I consistently deliver outstanding results and drive impactful change."
  //   },
  //   {
  //     info: "Beyond my role as a developer, I've had some exciting accomplishments along the way. I've participated in and achieved success in two hackathons, showcasing my ability to think creatively and solve complex problems. Additionally, my dedication to continuous improvement is reflected in my ranking among the top 5% on the Codewars Leaderboard, where I constantly challenge myself to refine my coding skills."
  //   },
  //   // {
  //   //   info: "In the spirit of knowledge sharing, I've had the opportunity to publish a technical paper, contributing to the advancement of the tech community. I find great excitement in staying ahead of the curve, exploring emerging technologies, and envisioning their potential impact on our industry."
  //   // },
  //   {
  //     info: "Beyond the realms of programming, I find solace in the enchanting world of poetry and music.  In my spare time, you can find me immersed in FL Studio or writing a rhyme or two, experimenting and creating melodies that bring me joy and inspiration  (•◡•) /"
  //   },
  //   {
  //     info: "I'm thrilled to have you here, and I invite you to explore my digital presence. Let's connect and discover how we can collaborate to drive innovation and growth in the world of software engineering. Welcome to my world, where passion meets code, and possibilities unfold. Together, let's make a difference!"
  //   }
  // ];

  const paragraphs = [
    {
      info: "Proficient in Full Stack Development with impactful experience in software engineering.⭐"
    },
    {
      info: "Winner of multiple hackathons, I'm always up for those challenges, Looking for a teammate? Shoot me a message on LinkedIn! 💪"
    },
    {
      info: "I have a tech blog with over 5,000 views, where I share the deep dives of Frontend concepts. 📈"
    },
    {
      info: "Seldom I do try to tune some melodies of FL Studio in which I'm pretty horrible 😄🎹"
    },
    {
      info: "These days, I enjoy my evenings, once a month, with good battle of Company of Heroes 2 or EA FC 2024 (Fifa was better, no?) ⚽🪖"
    },
  ]


  return (
    <>
      <Seo title="About" />
      <>
        <section className="about-page">
          <div className="section-center about-center">
            <StaticImage
              src="../assets/images/abhishek2.png"
              alt="Abhishek Shukla"
              className="about-img"
              placeholder="blurred"
              
            />
            <article className="about-text">
              <Title title="About Me" />
              <ul>
                {paragraphs.map((para,i) => {
                  return <li id={i} className="points">{para.info}</li>
                })}
              </ul>
              {/* <p>Welcome to my digital oasis! I'm Abhishek Shukla, a dynamic Software Engineer with an insatiable curiosity for all things Frontend. Currently pursuing my Masters in Engineering at the prestigious Concordia University in Montreal, I bring a powerful blend of academic excellence and hands-on expertise to the table.</p> */}
              <div className="about-stack">
                {stack.map(item => {
                  return <span key={item.id}>{item.title}</span>
                })}
              </div>
            </article>
          </div>
        </section>
      </>
    </>
  )
}

export default About
